import convert from "convert";
import {round} from "lodash-es";

export enum MeasurementsTypes {
    METRIC = "Metric",
    IMPERIAL = "Imperial"
}

export enum MeasurementTypes {
    WEIGHT = "WEIGHT",
    VOLUME = "VOLUME",
    TEMPERATURE = "TEMPERATURE",
    LENGTH = "LENGTH",
    SODIUM = "SODIUM"
}

//units that we get from backed for each measurement type
const currentWeightMeasurements = "milligram";
const currentVolumeMeasurements = "milliliter";
const currentTemperatureMeasurements = "celsius";
const currentLengthMeasurements = "meters";

//units that we want to show
export const baseMeasurementsUnits: {
    [key: string]: {
        [key: string]: {
            format: (val: number) => number,
            formatToBackEnd: (val: number) => number,
            sign: string
        }
    }
} = {
    [MeasurementTypes.WEIGHT]: {
        [MeasurementsTypes.METRIC]: {
            format: (value: number) => {
                return round(convert(value, currentWeightMeasurements).to("milligram"), 2);
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "milligram").to(currentWeightMeasurements), 0);
            },
            sign: "mg"
        },
        [MeasurementsTypes.IMPERIAL]: {
            format: (value: number) => {
                return round(convert(value, currentWeightMeasurements).to("oz"), 2);
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "oz").to(currentWeightMeasurements), 0);
            },
            sign: "oz"
        },
    },
    [MeasurementTypes.VOLUME]: {
        [MeasurementsTypes.METRIC]: {
            format: (value: number) => {
                return round(convert(value, currentVolumeMeasurements).to("ml"), 2);
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "ml").to(currentVolumeMeasurements), 0);
            },
            sign: "ml"
        },
        [MeasurementsTypes.IMPERIAL]: {
            format: (value: number) => {
                return round(convert(value, currentVolumeMeasurements).to("fl oz"), 2);
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "fl oz").to(currentVolumeMeasurements), 0);
            },
            sign: "fl oz"
        },
    },
    [MeasurementTypes.TEMPERATURE]: {
        [MeasurementsTypes.METRIC]: {
            format: (value: number) => {
                return value;
            },
            formatToBackEnd: (value: number) => {
                return value;
            },
            sign: "°C"
        },
        [MeasurementsTypes.IMPERIAL]: {
            format: (value: number) => {
                return convert(value, currentTemperatureMeasurements).to("fahrenheit");
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "fahrenheit").to(currentTemperatureMeasurements), 0);
            },
            sign: "°F"
        },
    },
    [MeasurementTypes.LENGTH]: {
        [MeasurementsTypes.METRIC]: {
            format: (value: number) => {
                return convert(value, currentLengthMeasurements).to("kilometers");
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "kilometers").to(currentLengthMeasurements), 0);
            },
            sign: "km"
        },
        [MeasurementsTypes.IMPERIAL]: {
            format: (value: number) => {
                return convert(value, currentLengthMeasurements).to("miles");
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "miles").to(currentLengthMeasurements), 0);
            },
            sign: "miles"
        },
    },
     [MeasurementTypes.SODIUM]: {
        [MeasurementsTypes.METRIC]: {
            format: (value: number) => {
                return round(convert(value, currentWeightMeasurements).to("milligram"), 2);
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "milligram").to(currentWeightMeasurements), 0);
            },
            sign: "mg"
        },
        [MeasurementsTypes.IMPERIAL]: {
            format: (value: number) => {
                return round(convert(value, currentWeightMeasurements).to("milligram"), 2);
            },
            formatToBackEnd: (value: number) => {
                return round(convert(value, "milligram").to(currentWeightMeasurements), 0);
            },
            sign: "mg"
        },
    },
};

export const MeasurementsTypesOptions = [
    {
        value: MeasurementsTypes.METRIC,
        title: MeasurementsTypes.METRIC
    },
    {
        value: MeasurementsTypes.IMPERIAL,
        title: MeasurementsTypes.IMPERIAL
    }
];
