import {useEffect, useRef} from "react";

type EffectCallback = () => void | (() => void | undefined);
type DependencyList = ReadonlyArray<any>;

export default function useDidUpdateEffect(
    fn: EffectCallback,
    inputs?: DependencyList,
) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
}
