import { UserService } from "@/client-side-service/user-service";
import { SiteService } from "./site-service";
import {StatsService} from "./stats-service";
import {ChHttpService} from "./ch-http-service";
import {EnterpriseService} from "./enterprise-service";
import {InviteService} from "@/client-side-service/invite-service";

export const chHttp = new ChHttpService();
export const enterpriseService = new EnterpriseService();
export const statsService = new StatsService();
export const siteService = new SiteService();
export const inviteService = new InviteService();
export const userService = new UserService();
