import {DeviceDataAPIResponseExtendedVersion, DeviceDataQuery} from "@/types/api-types";
import {BaseHttp} from "./base-http-service";

export class StatsService extends BaseHttp {

    constructor() {
        super("charts/stats-intermediate");
    }

    public getStatistic(params: DeviceDataQuery) {
        return this.post<DeviceDataAPIResponseExtendedVersion, DeviceDataQuery>("", params);
    }
}
