import {Enterprise} from "@/types/database-types";
import useDidUpdateEffect from "@/hooks/useDidUpdateEffect";
import {useUser} from "@auth0/nextjs-auth0";
import {UserProfile} from "@auth0/nextjs-auth0/src/frontend/use-user";
import {useRouter} from "next/router";
import React, {useCallback, useState} from "react";
import {DB_ROLES_NS, ROLES_NS} from "@/utils/auth0";
import {enterpriseService, inviteService} from "../client-side-service";
import {RoleLevel} from "@/enums/RoleLevel";
import {Role} from "@/utils/auth/utils";
import { setCookie, getCookie } from "cookies-next";
import {add} from "date-fns";

type Authorization = {
    roles?: Role[],
    selectedRole?: Role,
    selectedRoleLevel?: RoleLevel,
    setRole: (selected: Role) => void,
    enterprise?: Enterprise["enterprise_id"],//Deprecated
    enterpriseEntity?: Enterprise,
    userDataIsReady: boolean,
    invitationChecked: boolean,
}

type CHUserProfile = UserProfile & {
    [ROLES_NS]: string[]
    [DB_ROLES_NS]: string[]
}

const defaultValue = {
    setRole: (value: Role) => {
    },
    roles: [],
    userDataIsReady: false,
    invitationChecked: false,
};
const AuthorizationContext = React.createContext<Authorization>(defaultValue);

const AuthorizationProvider = function (props: React.PropsWithChildren<{}>) {
    const user = useUser()?.user as CHUserProfile;
    const [roles, setRoles] = useState<Role[] | undefined>(undefined);
    const [enterpriseEntity, setEnterpriseEntity] = useState<Enterprise>();
    const [selectedRoleLevel, setSelectedRoleLevel] = useState<RoleLevel>();
    const [selectedRole, setSelectedRole] = useState<Role>();
    const [userDataIsReady, setUserDataReady] = useState<boolean>(false);
    const [invitationChecked, setInvitationChecked] = useState(false);
    const router = useRouter();

    const setRole = useCallback(async (role: Role) => {
        reset();
        setCookie("selectedRole", role || "", {
            expires: add(new Date(), {
                years: 1,
            })
        });
        setSelectedRole(role);
        const adminTest = /ADMIN_(.+)/.exec(role);

        if (adminTest) {
            setSelectedRoleLevel(RoleLevel.ADMIN);
            const {data} = await enterpriseService.getById(adminTest[1]);
            setEnterpriseEntity(data);
        } else {
            setSelectedRoleLevel(role as RoleLevel);
        }

        setUserDataReady(true);
    }, []);

    useDidUpdateEffect(() => {
        if (user) {
            if(user[ROLES_NS] || user[DB_ROLES_NS]) {
                const userRoles: Role[] = [...user[ROLES_NS], ...user[DB_ROLES_NS]]! as Role[];
                setRoles(userRoles);
            }

            const checkInvite = async () => {
                //TODO remove this logic after implementing custom email verification
                const userEmail = user?.email;
                if (userEmail) {

                    const {data} = await inviteService.getInviteByUserEmail(userEmail);
                    if (data && !data.is_accepted) {
                        await router.push(`/invitation-confirmation/${data.hash}`);
                    }
                }
                setInvitationChecked(true);
            };

            checkInvite();
        }
        return;
    }, [user]);

    useDidUpdateEffect(() => {
        const savedSelectedRole = getCookie("selectedRole") as Role;
        if (roles) {
            if(!!roles.length) {
                if (!savedSelectedRole || !roles.includes(savedSelectedRole)) {
                    setRole(roles[0]);
                } else {
                    setRole(savedSelectedRole);
                }
            } else {
                setRole(RoleLevel.NO_ROLE);
            }
        }
       
    }, [roles, setSelectedRole]);

    function reset() {
        setSelectedRole(undefined);
        setSelectedRoleLevel(undefined);
        setEnterpriseEntity(undefined);
        setUserDataReady(false);
    }

    return (<AuthorizationContext.Provider
        value={{
            roles,
            setRole,
            selectedRole,
            selectedRoleLevel,
            userDataIsReady,
            enterpriseEntity,
            invitationChecked
        }}>
        {props.children}
    </AuthorizationContext.Provider>);
};

export {AuthorizationProvider, AuthorizationContext};
export type {Authorization, CHUserProfile};
