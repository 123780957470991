import axios, {Axios, AxiosPromise, AxiosRequestConfig} from "axios";
import qs from "qs";

export abstract class BaseHttp {
    protected axios: Axios;

    constructor(private url: string) {
        //in future here we can add to our axios transformRequest, paramsSerializer, interceptors etc
        this.axios = axios.create({
            baseURL: `/api/${url}`,
            paramsSerializer: (params) => {
                return qs.stringify(params, {indices: false});
            },
        });
    }

    get<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axios.get(url, config);
    }

    post<T, D>(url: string, data?: D, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axios.post(url, data, config);
    }

    put<T, D>(url: string, data?: D, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axios.put(url, data, config);
    }

    delete<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axios.delete(url, config);
    }
}
