import {Enterprise, Site} from "@/types/database-types";
import {BaseCrudService} from "./base-crud-service";

export class SiteService extends BaseCrudService<Site, string> {
    constructor() {
        super("pg/site");
    }

    public async getSitesByEnterpriseId(enterprise_id: string) {
        return this.get<{
            sites: Array<Site>,
            enterprise: Enterprise
        }>("get-by-enterprise-id", {
            params: {enterprise_id}
        });
    }

    public async getByCompositeId(site_id: string, enterprise_id: string) {
        return this.get<Site>("", {
            params: {site_id, enterprise_id}
        });
    }

    public async deleteByCompositeId(site_id: string, enterprise_id: string) {
        return this.delete<Site>("", {
            params: {site_id, enterprise_id}
        });
    }

}
