import {Enterprise, Site} from "@/types/database-types";
import {BaseCrudService} from "./base-crud-service";

export class EnterpriseService extends BaseCrudService<Enterprise, string> {
    constructor() {
        super("pg/enterprise");
    }

    public async create(enterprise: Enterprise) {
        return super.create(enterprise);
    }

    public async update(enterprise: Enterprise) {
        return super.update(enterprise);
    }

    public async getById(id: string) {
        return super.getById(id);
    }

    public async getAllEnterprises() {
        return this.get<Array<Enterprise & {
            sites: Array<Site>
            _count: {
                users: number
            }
        }>>("all");
    }

    public async deleteById(id: string) {
        return super.deleteById(id);
    }
}
