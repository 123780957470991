import MeasurementsContext from "@/components/MeansurementsContext";
import { MeasurementsTypes } from "@/enums/MeasurementsUnits";
import React, { useEffect, useState } from "react";

const MeasurementsProvider = (props: React.PropsWithChildren<{}>) => {
    const [currentMeasurements, setCurrentMeasurements] = useState<MeasurementsTypes>(MeasurementsTypes.METRIC);
    useEffect(() => {
        if (localStorage && localStorage.getItem("measurements-type")) {
            setCurrentMeasurements(localStorage.getItem("measurements-type") as MeasurementsTypes);
        }
    }, []);

    const setMeasurements = (measurements: MeasurementsTypes) => {
        setCurrentMeasurements(measurements);
        localStorage.setItem("measurements-type", measurements);
    };
    
    return (
        <MeasurementsContext.Provider value={[currentMeasurements, setMeasurements]}>
            {props.children}
        </MeasurementsContext.Provider>
    );
};

export default MeasurementsProvider;
