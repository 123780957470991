import {UserProvider} from "@auth0/nextjs-auth0";
import {ThemeProvider} from "@mui/material";
import type {AppProps} from "next/app";
import React, {Component} from "react";
import "../styles/globals.css";
import {AuthorizationProvider} from "@/components/Authorization";
import {muiThemeOptions} from "../styles/muiTheme";
import MeasurementsProvider from "@/components/MeasurementsProvider";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";

import("@datadog/browser-rum")
    .then(obj => {
        const {datadogRum} = obj;
        try {
            datadogRum.init({
                applicationId: "de1744af-6871-4c3f-93c9-0a0716724601",
                clientToken: "pub864166aca540b481ff13af01586d3146",
                site: "datadoghq.com",
                service: "default-service",
                env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
                sampleRate: 100,
                premiumSampleRate: 100,
                trackInteractions: true,
                defaultPrivacyLevel:"mask-user-input"
            });

            datadogRum.startSessionReplayRecording();
        } catch (e) {
            console.error("Datadog RUM initialization error");
        }
    })
    .catch(err => {
        console.error("Datadog RUM import error", err);
    });

function EpicoreCloudApp({Component, pageProps}: AppProps) {
    return <UserProvider>
        <AuthorizationProvider>
            <MeasurementsProvider>
                <ThemeProvider theme={muiThemeOptions}>
                    <Head>
                        <title>Epicore Biosystems Cloud</title>
                    </Head>
                    <Component {...pageProps} />
                </ThemeProvider>
            </MeasurementsProvider>
        </AuthorizationProvider>
        <ToastContainer theme="colored"/>
    </UserProvider>;
}

export default EpicoreCloudApp;
