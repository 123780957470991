import {BaseCrudService} from "@/client-side-service/base-crud-service";
import {Enterprise, Invite} from "@/types/database-types";

export class InviteService extends BaseCrudService<Invite, string> {

    constructor() {
        super("pg/invite");
    }

    public async sendInvite(email: string, enterpriseId: string) {
        return this.post<string | undefined, {
            email: string,
            enterpriseId: string
        }>("send-invite", {email, enterpriseId});
    }


    public async acceptInvite(hash: string) {
        return this.post<{ message: string }, { hash: string }>("accept-invite", {hash});
    }

    public async declineInvite(hash: string) {
        return this.post<{ message: string }, { hash: string }>("decline-invite", {hash});
    }

    public async getInviteByUserEmail(email: string) {
        return this.get<Invite | null>("get-by-user-email", {
            params: {
                email
            }
        });
    }

    public async getWithEnterprise(hash: string) {
        return this.get<Invite & { enterprise: Enterprise } | null>("get-with-enterpise", {
            params: {
                id: hash
            }
        });
    }
}
