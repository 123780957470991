import {Invite, User, UserRoles} from "@/types/database-types";
import {BaseCrudService} from "./base-crud-service";

export class UserService extends BaseCrudService<User, string> {

    constructor() {
        super("pg/user");
    }

    public async getById(id: string) {
        return this.get<User>("", {
            params: {
                id
            }
        });
    }

    public async deleteByEmail(email: string) {
        return this.delete<{ message: string }>("delete-user", {
            params: {
                email
            }
        });
    }

    public async getAdminsForEnterprise(enterpriseId: string) {
        return this.get<Array<User & { roles: Array<UserRoles>, invite: Invite | null }>>("get-admins", {
            params: {
                enterprise_id: enterpriseId
            }
        });
    }
}
