import {createTheme, ThemeOptions} from "@mui/material";

export const primary = "#6E8CA0";
export const primaryDark = "#334856";
export const textPrimary = "#000000";
export const paper = "#F8F8F8";
export const secondary = "#F8F8F8";
export const secondaryDark = "#f0f0f0f0";
export const baseBackground = "#fff";
export const baseColor1 = "#999999";

export const info = "#757575";

export const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue' , helvetica, Ubuntu, roboto, noto, arial, sans-serif"
    },
    palette: {
        primary: {
            main: primary,
            dark: primaryDark,
        },
        secondary: {
            main: secondary,
            dark: secondaryDark
        },
        info: {
            main: info,
        },
        background: {
            default: baseBackground,
            paper: paper
        },
        text: {
            primary: textPrimary
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontFamily: "Orbitron",
                    fontWeight: "600",
                    fontSize: "1.55rem",
                    lineHeight: "1.55rem",
                    paddingTop: "45px",
                    paddingBottom: "30px"
                },
                subtitle2: {
                    color: baseColor1,
                    fontWeight: "400",
                    fontSize: "0.8rem",
                    lineHeight: "0.8rem"
                },
                h3: {
                    color: baseColor1,
                    fontSize: "0.8rem",
                    lineHeight: "1.5rem",
                },
                body1: {
                    color: textPrimary
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    padding: "10px",
                    background: baseBackground
                },
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    ":hover": {
                        textDecoration: "none",
                        color: primary,
                    }
                },
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "filled",
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    background: paper,
                    "&:hover": {
                        background: paper,
                    },
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    background: paper,
                    "&:hover": {
                        background: paper,
                    },
                    userSelect: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    "&.Mui-disabled": {
                        "&:before": {
                            borderBottomStyle: "unset"
                        }
                    },
                    "&.Mui-focused": {
                        background: paper,
                    }
                },
                input: {
                    "&:hover": {
                        background: paper,
                    },
                    background: paper,
                    borderRadius: "8px",
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    padding: "0.75rem 1rem",
                    minHeight: "1rem",
                    height: "25px",
                    boxShadow: "none !important",
                    cursor: "pointer",
                    "&.MuiSelect-select": {
                        minHeight: "1rem",
                        lineHeight: "1rem",
                    },
                    "&.Mui-disabled": {
                        cursor: "default"
                    }
                },
            }
        },
        MuiInputBase: {
            defaultProps: {
                inputProps: {},
            },
            styleOverrides: {
                root: {
                    background: paper
                },
                input: {
                    background: paper,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    paddingRight: "14px",
                    "&:hover": {
                        background: "none"
                    }
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                },
                grouped: {
                    borderRight: "none",
                    borderLeft: "none",

                    "&:not(:last-of-type)": {
                        borderRight: "none",
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "normal",
                    "&:hover": {
                        boxShadow: "2px 2px 5px #fcfcfc"
                    },
                    color: baseBackground,
                    borderRadius: "5px",
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    paddingBottom: "0.75rem",
                    paddingTop: "0.75rem",
                    textTransform: "none",
                    boxShadow: "none"
                },
                containedSecondary: {
                    color: textPrimary,
                }
            }
        }
    },
};

export const muiThemeOptions = createTheme(themeOptions);
