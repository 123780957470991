import {BaseHttp} from "./base-http-service";

export class BaseCrudService<T, K> extends BaseHttp {

    protected constructor(path: string) {
        super(path);
    }

    public async create(object: T, url = "") {
        return this.post<T, T>(url, object);
    }

    public async update(object: T, url = "") {
        return this.put<T, T>(url, object);
    }

    public async getById(id: K, url = "") {
        return this.get<T>(url, {
            params: {id}
        });
    }

    public async getByIds(ids: K[], url = "") {
        return this.get<T[]>(url, {
            params: {ids}
        });
    }

    public async deleteById(id: K, url = "") {
        return this.delete<T>(url, {
            params: {id}
        });
    }

}
