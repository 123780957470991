import {BaseHttp} from "./base-http-service";
import {LocationDetails, UserSensor} from "@/types/database-types";

export class ChHttpService extends BaseHttp {
    constructor() {
        super("ch");
    }

    async getAvailableLocations(enterpriseId?: string) {
        return this.get<Array<LocationDetails>>("get-locations-by-filter", {
            params: {
                enterpriseId
            }
        });
    }

    async getSensorByUserId(id: string, start: string, end: string) {
        return this.get<UserSensor[]>("get-sensors-by-user-id", {
            params: {
                id,
                start,
                end
            }
        });
    }

}
